import { useDispatch } from 'react-redux';
import React from 'react';
import styled from 'styled-components/macro';
import { actionCreator } from '../../shared/redux/actionHelper';
import { CurrentPage } from '../../redux/currentPage/types';
import { calcAIProcessingStatus } from '../../utils/helpers';
import { openModalType } from '../../utils/constants';
import { ReactComponent as AISvg } from '../../images/icons/sparkles.svg';
import { ReactComponent as RetrySvg } from '../../images/icons/batchActions/retry.svg';
import { actionOpenAIModal } from '../../redux/user/action';

interface UsedByAIIndicatorShellProps {
  borderColor: string;
  backgroundColor: string;
  svgColor: string;
  hasIndention: boolean;
}

const UsedByAIIndicatorShell = styled.div<UsedByAIIndicatorShellProps>`
  ${({ borderColor }) => `border: 1px solid ${borderColor};`}
  ${({ backgroundColor }) => `background: ${backgroundColor};`}
  display: flex;
  gap: 5px;
  margin-right: 3px;
  width: 20px;
  max-width: 20px;
  overflow: hidden;
  transition: all 0.3s ease-in;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  z-index: 10;
  top: -10px;
  ${({ hasIndention }) => (hasIndention ? 'left: 46px;' : 'left: -4px;')}
  
  .indicator_text,
  .retry_icon {
    display: none;
    margin: 0;
  }
  &:hover {
    width: fit-content;
    max-width: 200px;
    min-width: 80px;
    & .indicator_text{
      padding-right: 5px;
    }
    .indicator_text,
    .retry_icon {
      display: block;
    }
  }
  ${({ svgColor }) => `
    svg path {
      fill: ${svgColor};
    }
  `}
  .retry_icon path {
    fill: #514E55;
  }
`;

interface UsedByAIIndicatorCompProps {
  parsedStatusByAi: string | null | undefined;
  linkPage: any;
  hasIndention?: boolean | null;
  isTitle?: boolean | null;
}

export const UsedByAIIndicatorComp: React.FC<UsedByAIIndicatorCompProps> = ({ parsedStatusByAi, hasIndention, linkPage, isTitle }) => {
  const dispatch = useDispatch();
  const aIProcessingSet = calcAIProcessingStatus(parsedStatusByAi, isTitle);
  const canBeRetry = aIProcessingSet.isError || aIProcessingSet.isEmpty;

  const aiTaskHandler = (e: React.MouseEvent) => {
    e.stopPropagation();
    dispatch(actionCreator(CurrentPage.sendToAI, { linkPages: [linkPage.id], shouldNotDisplayProcessing: true }));
  };

  const handleClickSummary = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (isTitle) return;
    if (linkPage.libraryComponent?.summaryAI || linkPage.textComponent?.summaryAI) {
      dispatch(actionOpenAIModal(openModalType.AIUniversal, {
        item: linkPage,
        aiType: 'ai_summarize_single',
      }));
    }
  };

  return (
    <UsedByAIIndicatorShell
      hasIndention={!!hasIndention}
      borderColor={aIProcessingSet.borderColor}
      backgroundColor={aIProcessingSet.backgroundColor}
      svgColor={aIProcessingSet.svgColor}
      onClick={handleClickSummary}
    >
      <AISvg />
      <p className="indicator_text">{aIProcessingSet.text}</p>
      {canBeRetry && (
        <RetrySvg className="retry_icon" onClick={aiTaskHandler} />
      )}
    </UsedByAIIndicatorShell>
  );
};
