import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ReactComponent as UndoneSvg } from '../../images/icons/undone.svg';
import { ReactComponent as SummarizeSvg } from '../../images/icons/batchActions/summarize.svg';
import { ReactComponent as LibSvg } from '../../images/icons/batchActions/add_to_lib.svg';
import { ReactComponent as DeleteSvg } from '../../images/icons/batchActions/delete.svg';
import { ReactComponent as LockSvg } from '../../images/icons/batchActions/lock.svg';
import { ReactComponent as UnlockSvg } from '../../images/icons/batchActions/unlock.svg';
import { ReactComponent as DuplicateSvg } from '../../images/icons/batchActions/copy.svg';
import { ReactComponent as DownloadSvg } from '../../images/icons/batchActions/download.svg';
import { ReactComponent as AddToSvg } from '../../images/icons/batchActions/add_to.svg';
import { ReactComponent as TranslateSvg } from '../../images/icons/batchActions/translate.svg';
import { ReactComponent as SelectSvg } from '../../images/icons/batchActions/select_all.svg';
import {
  PopupWrapper,
  SelectedItemsCounter, PopupHeader,
  HorizontalDivider, PopupSectionTitle,
  ItemsSection, SingleItem, HotkeyWrapper,
  ItemName,
} from './ai_styled';
import { DATA_PARENTS, MessageType } from '../../utils/constants';
import { actionSelectItemsInSmartfile } from '../../redux/currentPage/action';
import { actionShowMessage } from '../../redux/support/action';

interface IItem {
  id: string,
  isRemixLocked: boolean,
  libraryComponent: {
    id: string,
  }
}

interface IBatchActionsPopup {
  popupRef: React.MutableRefObject<HTMLDivElement | null>,
  selectedItems: { [key: string]: boolean },
  setClosed: React.Dispatch<React.SetStateAction<string | boolean>>
  addToLibraryHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  summarizeHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  translateHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  duplicateHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  downloadHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  deleteHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  lockHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  addToSmartfileHandler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  isDisabledByRole?: boolean,
  isSixDots?: boolean,
  item?: IItem,
  isLocked?: boolean;
}

interface ISingleOption {
  name: string,
  icon: any,
  hotKey: string,
  type: string,
  isDelete?: boolean,
}


const PopupSingleItem: React.FC<{
  elem: ISingleOption, isStroke?: boolean,
  setClosed: React.Dispatch<React.SetStateAction<string | boolean>>,
  handler: (e: React.MouseEvent<Element, MouseEvent>) => void,
  dispatch: any,
  isDisabled?: boolean,
  item?: IItem,
  selectedItems:string[],
}> = ({ elem, isStroke, setClosed, handler,
  isDisabled, dispatch,
}) => {
  const onClickHandler = (e: React.MouseEvent) => {
    setClosed(false);
    if (isDisabled) {
      dispatch(
        actionShowMessage({
          type: MessageType.NoItemsSelected,
        }),
      );
      return;
    }
    handler(e);
  };

  return (
    <SingleItem
      onClick={onClickHandler}
      data-parent={DATA_PARENTS.checkItem}
      isAI={isStroke}
    >
      <ItemName isDelete={elem.isDelete} data-parent={DATA_PARENTS.checkItem}>
        {elem.icon}
        {elem.name}
      </ItemName>
      <HotkeyWrapper isDelete={elem.isDelete} data-parent={DATA_PARENTS.checkItem}>
        {elem.hotKey}
      </HotkeyWrapper>
    </SingleItem>
  );
};

export const BatchActionsPopup: React.FC<IBatchActionsPopup> = ({
  popupRef,
  selectedItems,
  isSixDots,
  setClosed,
  duplicateHandler,
  downloadHandler,
  deleteHandler,
  lockHandler,
  summarizeHandler,
  translateHandler,
  addToLibraryHandler,
  addToSmartfileHandler,
  item,
  isLocked,
  isDisabledByRole,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const optionsObj = {
    regular: [
      {
        handler: downloadHandler,
        name: t('downloadT'),
        icon: <DownloadSvg />,
        hotKey: 'd',
        type: 'download',
      },
      {
        handler: duplicateHandler,
        name: t('duplicateT'),
        icon: <DuplicateSvg />,
        hotKey: 'c',
        type: 'duplicate',
        isDisabled: isDisabledByRole,
      },
      {
        handler: lockHandler,
        name: (isSixDots && item?.isRemixLocked || isLocked) ? t('unlockT') : t('lockT'),
        icon: (isSixDots && item?.isRemixLocked || isLocked) ? <UnlockSvg /> : <LockSvg />,
        hotKey: 'l',
        type: 'lock',
        isDisabled: isDisabledByRole,
      },
      {
        handler: addToSmartfileHandler,
        name: t('addToAnotherSmartfileDropdownOptionT'),
        icon: <AddToSvg />,
        hotKey: 'm',
        type: 'addToSF',
      },
      {
        handler: addToLibraryHandler,
        name: t('addToLibraryDropdownOptionT'),
        icon: <LibSvg />,
        hotKey: 'n',
        type: 'addToLib',
      },
    ],
    ai: [
      {
        handler: summarizeHandler,
        name: t('summarizeT'),
        icon: <SummarizeSvg />,
        hotKey: 's',
        type: 'summarize',
      },
      {
        handler: translateHandler,
        name: t('translateT'),
        icon: <TranslateSvg />,
        hotKey: 't',
        type: 'translate',
      },
      {
        handler: deleteHandler,
        name: t('deleteT'),
        icon: <DeleteSvg />,
        hotKey: 'Delete',
        type: 'delete',
        isDelete: true,
        isDisabled: isDisabledByRole,
      },
    ],
  };

  const handleSelect = () => {
    dispatch(actionSelectItemsInSmartfile(true));
  };

  const isDisabled = !isSixDots && Object.keys(selectedItems).length === 0;

  return (
    <PopupWrapper ref={popupRef}>
      {!isSixDots && (
        Object.keys(selectedItems).length ? (
          <PopupHeader>
            <UndoneSvg />
            <SelectedItemsCounter>
              {Object.keys(selectedItems).length}
            </SelectedItemsCounter>
            {t('sourcesSelectedT')}
          </PopupHeader>
        ) : (
          <SingleItem
            onClick={handleSelect}
            data-parent={DATA_PARENTS.checkItem}
            isAI
          >
            <ItemName data-parent={DATA_PARENTS.checkItem}>
              <SelectSvg data-parent={DATA_PARENTS.checkItem} />
              {t('selectAllT')}
            </ItemName>
            <div data-parent={DATA_PARENTS.checkItem} />
          </SingleItem>
        )
      )}
      {!isSixDots && <HorizontalDivider data-parent={DATA_PARENTS.checkItem} />}
      <PopupSectionTitle data-parent={DATA_PARENTS.checkItem}>
        {t('manageT')}
      </PopupSectionTitle>
      <ItemsSection data-parent={DATA_PARENTS.checkItem}>
        {optionsObj.regular.map(elem => (
          elem.isDisabled ? <></>
            : (
              <PopupSingleItem
                key={elem.type}
                elem={elem}
                setClosed={setClosed}
                handler={elem.handler}
                item={item}
                isStroke={elem.type === 'addToLib'}
                isDisabled={isDisabled}
                dispatch={dispatch}
                selectedItems={Object.keys(selectedItems)}
              />
            )
        ))}
      </ItemsSection>
      <HorizontalDivider data-parent={DATA_PARENTS.checkItem} />
      <PopupSectionTitle data-parent={DATA_PARENTS.checkItem}>
        {t('aIAssistantT')}
      </PopupSectionTitle>
      <ItemsSection data-parent={DATA_PARENTS.checkItem}>
        {optionsObj.ai.map(elem => (
          elem.isDisabled ? <></>
            : (
              <PopupSingleItem
                key={elem.type}
                elem={elem}
                selectedItems={Object.keys(selectedItems)}
                isStroke={elem.type !== 'delete'}
                setClosed={setClosed}
                handler={elem.handler}
                isDisabled={isDisabled}
                dispatch={dispatch}
              />
            )
        ))}
      </ItemsSection>
    </PopupWrapper>
  );
};
